import React, { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import parse from 'html-react-parser'

import { SportsList } from '../../const/sports-list'
import ErrorMessage from '../common/error-message'
import { sendOtp } from '../../services/send-otp'
import { portalSignup } from '../../services/portal-signup'
import { verifyOtp } from '../../services/verify-otp'
import { createProfile } from '../../services/create-profile'
import { getOrganizationList } from '../../services/get-organization-list'
import { privacyPolicy } from '../../services/privacy-policy'
import { EMAIL_REGEX, NAME_REGEX } from '../../const'

import MaleIcon from '../../icons/Sportskeyz-male.png'
import FemaleIcon from '../../icons/Sportskeyz-female.png'

import 'react-datepicker/dist/react-datepicker.css'

import { ReactComponent as Male } from '../../icons/male.svg'
import { ReactComponent as Female } from '../../icons/female.svg'
import { ReactComponent as Calender } from '../../icons/calender.svg'
import { ReactComponent as Arrow } from '../../icons/arrow.svg'

export default function Registration({ setTab }) {
    const [phoneEmail, setPhoneEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [accountFor, setAccountFor] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDOB] = useState(new Date())
    const [organizationName1, setOrganizationName1] = useState('')
    console.log(organizationName1)
    const [organizationName2, setOrganizationName2] = useState('')
    const [organizationList, setOrganizationList] = useState([])
    const [policyContent, setPolicyContent] = useState([])
    const [sportsLiked, setSportsLiked] = useState([])
    const [qualification, setQualification] = useState('')
    const ContainerRef = useRef()
    const [searchTerm, setSearchTerm] = useState('')
    const [error, setError] = useState('')
    const [time, setTime] = useState(120)
    const intervalRef = useRef(null)
    // const [randomNumber, setRandomNumber] = useState(() => {
    //     let number = ''
    //     for (let i = 0; i < 10; i++) {
    //         number += Math.floor(Math.random() * 10)
    //     }
    //     return number
    // })
    const [isOtpVerified, setIsOtpVerified] = useState(false)

    // section toggle flags
    const [showOtpSection, setShowOtpSection] = useState(false)
    const [showAccountForSection, setShowAccountForSection] = useState(false)
    const [showNameSection, setShowNameSection] = useState(false)
    const [showGenderSection, setShowGenderSection] = useState(false)
    const [showBDSection, setShowBDSection] = useState(false)
    const [showOrganizationSection, setShowOrganizationSection] = useState(false)
    const [showSportsSection, setShowSportsSection] = useState(false)
    const [showQualificationSection, setShowQualificationSection] = useState(false)
    const [showPrivacySection, setShowPrivacySection] = useState(false)

    const [spid, setSpId] = useState('')
    const [isPrivacySectionScroll, setIsPrivacySectionScrolled] = useState(false)
    const privacyContainerRef = useRef(null)

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
    }

    const filteredSports =
        searchTerm.length >= 3 ? SportsList.filter((sport) => sport.label.toLowerCase().includes(searchTerm.toLowerCase())) : SportsList

    const handleEmailSubmit = (e) => {
        e.preventDefault()

        if (phoneEmail.length < 3) {
            toast.error('Email length should be greater than 3 characters', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (phoneEmail.length > 50) {
            toast.error('Email length should be less than 50 characters', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (!EMAIL_REGEX.test(phoneEmail)) {
            toast.error('Invalid Email', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            return
        }

        portalSignup({ email: phoneEmail }, () => {
            setShowOtpSection(true)
        })
    }

    const handleOrgInputSubmit = (e) => {
        e.preventDefault()

        if (organizationName2.length < 3) {
            toast.error(`Organization name length should be greater than 3 characters`, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (organizationName2.length > 50) {
            toast.error('Organization length should be less than 50 characters', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (!NAME_REGEX.test(organizationName2)) {
            toast.error('Organization name must be Alpha or Alphanumeric', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        setShowSportsSection(true)
    }

    const handleOtpChange = async (e) => {
        setOtp(e)
        setError('')

        if (e.length >= 6) {
            // below line is for testing the flow without use of api

            // setSpId('1301-3990-1747')
            // setShowAccountForSection(true)
            // localStorage.setItem('access-token', res.accessToken)'
            // setIsOtpVerified(true)

            await verifyOtp(
                { otp: e, phoneEmail },
                (res) => {
                    setSpId(res.spid)
                    setShowAccountForSection(true)
                    localStorage.setItem('access-token', res.accessToken)
                    setIsOtpVerified(true)
                    setSpId(res.spid)
                    setShowAccountForSection(true)
                    localStorage.setItem('access-token', res.accessToken)
                    setIsOtpVerified(true)
                },
                () => {
                    setError('Please enter a valid code')
                }
            )
            await getOrganizationList((res) => {
                setOrganizationList(res.organizations)
            })
            await privacyPolicy((res) => {
                setPolicyContent(res.content)
            })
        }
    }

    const handleSportSelect = (value) => {
        let oldSports = sportsLiked
        if (oldSports.includes(value)) {
            oldSports = oldSports.filter((item) => item !== value)
        } else {
            if (oldSports.length >= 3) return
            oldSports.push(value)
        }
        setSportsLiked([...oldSports])

        // below line is for testing the flow without use of api

        // setShowQualificationSection(true)
        // setSportsLiked((oldSports) => {
        //     console.log(value)
        //     if (oldSports.includes(value)) {
        //         return oldSports.filter((item) => item != value)
        //     }
        //     oldSports.push(value)
        //     return [...oldSports]
        // })
    }

    const handleCreateProfile = () => {
        let formattedDate = formatDate(dob)

        // Start with the properties that are always included
        let profileData = {
            spid,
            firstName,
            lastName,
            email: phoneEmail,
            dob: formattedDate,
            gender,
            accountFor,
            qualification,
            sportsLiked,
            trustMeterScore: '350',
            avatar: '', // Initialize avatar as an empty string
        }

        // Conditionally set the avatar based on gender
        if (gender === 'Male') {
            profileData.avatar = MaleIcon
        } else if (gender === 'Female') {
            profileData.avatar = FemaleIcon
        }

        // Conditionally add organizationId or organizationName
        if (organizationName1) {
            console.log({ organizationName1 })
            profileData['organizationID'] = organizationName1
            console.log(profileData)
        } else if (organizationName2) {
            profileData.organizationName = organizationName2
        }

        createProfile(profileData, (res) => {
            setTab('Login')
            toast('You have registered successfully!', {
                style: {
                    background: '#19191A',
                    borderRadius: '12px',
                    color: '#fff',
                    fontSize: '45px',
                    padding: '10px 20px',
                    maxWidth: 'unset',
                },
            })
        })
    }

    useEffect(() => {
        if (!showPrivacySection) return
        privacyContainerRef.current.addEventListener('scroll', (e) => {
            if (Math.ceil(e.target.scrollTop) >= e.target.scrollHeight - e.target.offsetHeight) {
                setIsPrivacySectionScrolled(true)
            }
        })
    }, [showPrivacySection])

    const resendOtp = async (event) => {
        event.preventDefault()
        sendOtp(phoneEmail, () => {})
        setTime(120)
    }

    useEffect(() => {
        if (showOtpSection) {
            ContainerRef.current.scroll(null, 150)
            intervalRef.current = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(intervalRef.current)
                        return prevTime
                    }
                    return prevTime - 1
                })
            }, 1000)

            return () => {
                clearInterval(intervalRef.current)
            }
        }
    }, [showOtpSection])

    const minutes = Math.floor(time / 60)
    const seconds = time % 60

    useEffect(() => {
        if (showAccountForSection) {
            ContainerRef.current.scroll(null, 500)
        }
    }, [showAccountForSection])

    useEffect(() => {
        if (accountFor) {
            setShowNameSection(true)
        }
    }, [accountFor])

    useEffect(() => {
        if (showNameSection) {
            ContainerRef.current.scroll(null, 1000)
        }
    }, [showNameSection])

    useEffect(() => {
        if (showGenderSection) {
            ContainerRef.current.scroll(null, 1300)
        }
    }, [showGenderSection])

    useEffect(() => {
        if (gender) {
            setShowBDSection(true)
            ContainerRef.current.scroll(null, 1500)
        }
    }, [gender])

    useEffect(() => {
        if (showBDSection) {
            ContainerRef.current.scroll(null, 1800)
        }
    }, [showBDSection])

    useEffect(() => {
        if (showOrganizationSection) {
            ContainerRef.current.scroll(null, 2000)
        }
    }, [showOrganizationSection])

    useEffect(() => {
        if (organizationName1) {
            setShowSportsSection(true)
        }
    }, [organizationName1])

    useEffect(() => {
        if (showSportsSection) {
            ContainerRef.current.scroll(null, 2625)
        }
    }, [showSportsSection])

    useEffect(() => {
        if (showQualificationSection) {
            ContainerRef.current.scroll(null, 3500)
        }
    }, [showQualificationSection])

    useEffect(() => {
        if (qualification) {
            setShowPrivacySection(true)
        }
    }, [qualification])

    useEffect(() => {
        if (showPrivacySection) {
            ContainerRef.current.scroll(null, 4500)
        }
    }, [showPrivacySection])

    function formatDate(date) {
        let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [day, month, year].join('-')
    }

    return (
        <div action='' className={`relative h-full overflow-auto scroll-smooth flex flex-col gap-[100px]`} ref={ContainerRef}>
            <div className='phone-email-wrap'>
                <div className='form-heading'>
                    <p className='title'>Get Started...</p>
                    <p className='sub-title'>Press Enter to go to the next step</p>
                </div>
                <div className='form-group'>
                    <form action='' onSubmit={handleEmailSubmit}>
                        <p>Let’s complete the basics</p>
                        <label htmlFor='emailphone'>Enter your Email or phone number</label>
                        <input type='text' value={phoneEmail} onChange={(e) => setPhoneEmail(e.target.value)} disabled={showOtpSection} />
                    </form>
                </div>
                {/* <div className='continue-using-wrap pt-10'>
                    <p className='text-lg font-medium leading-none text-white pb-5 block'>Or continue using</p>
                    <ul className='flex gap-4'>
                        <li>
                            <a href='#'>
                                <img src='images/google.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/facebook.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/linkedin.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/instagram.svg' alt='' />
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>

            {showOtpSection && (
                <div className='verification-wrap '>
                    <div className='form-heading'>
                        <p className='title'>Verification</p>
                    </div>
                    <div className='form-group'>
                        <p>Enter the code sent on</p>
                        <label htmlFor='emailphone'>{phoneEmail}</label>
                        <div className='text-center'>
                            <div className='otp-input-wrap pb-3'>
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputType='tel'
                                    inputStyle={{
                                        width: '50px',
                                        height: '80px',
                                        border: '0',
                                        margin: '0 2px',
                                        padding: '21px 14px',
                                        fontSize: '34px',
                                        color: error ? '#E30B5C' : '#ffffff',
                                        fontWeight: '400',
                                        caretColor: '#ffffff',
                                        outline: 'none',
                                        background: '#23282E',
                                    }}
                                    focusStyle={{
                                        border: '0',
                                        outline: 'none',
                                    }}
                                    renderInput={(props) => <input {...props} placeholder='X' disabled={showAccountForSection} />}
                                />
                            </div>
                            {!isOtpVerified && (
                                <div>
                                    <div className={`py-[12px] leading-[1] text-white`}>
                                        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                                    </div>
                                    <div
                                        className={`resend-otp-box py-[12px] flex gap-[8px] justify-center items-center  ${
                                            time > 0 && !error && 'opacity-50'
                                        }`}>
                                        {error ? (
                                            <ErrorMessage message={error} />
                                        ) : (
                                            <p className='text-xs leading-[1] !pb-0'>Didn’t receive the verification OTP?</p>
                                        )}

                                        <button
                                            className='text-sm text-accent leading-[1] disabled:cursor-not-allowed'
                                            disabled={time > 0 && !error}
                                            type='button'
                                            onClick={resendOtp}>
                                            Resend Again
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showAccountForSection && (
                <div className='account-for-wrap '>
                    <div className='form-group'>
                        <p>Let’s set up your account</p>
                        <label htmlFor='emailphone'>You are creating account for?</label>
                        <div className='radio-btn-wrap  flex flex-wrap gap-y-4 gap-x-2 justify-end'>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='account-for' value='Myself' onChange={(e) => setAccountFor(e.target.value)} />
                                <span>Myself</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='account-for' value='My Child' onChange={(e) => setAccountFor(e.target.value)} />
                                <span>My Child</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='account-for' value='My Student' onChange={(e) => setAccountFor(e.target.value)} />
                                <span>My Student</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='account-for' value='Someone I know' onChange={(e) => setAccountFor(e.target.value)} />
                                <span>Someone I know</span>
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {showNameSection && (
                <div className='identity-wrap '>
                    <div className='form-heading'>
                        <p>Welcome aboard</p>
                        <p className='subtitle'>
                            Hello I’m your digital sports identity, Sportskeyz.
                            <br />
                            {accountFor === 'Myself' ? 'And you are?' : 'And the name of the account holder is?'}
                        </p>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()

                            if (!firstName || !lastName) {
                                toast.error('Firstname and Lastname is required', {
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                        maxWidth: 'unset',
                                    },
                                })
                                return
                            }
                            if (firstName.length < 3 || lastName.length < 3) {
                                toast.error(`Firstname and Lastname length should be greater than 3 characters`, {
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                        maxWidth: 'unset',
                                    },
                                })
                                return
                            }
                            if (firstName.length > 16 || lastName.length > 16) {
                                toast.error('Firstname and Lastname length should be less than 16 characters', {
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                        maxWidth: 'unset',
                                    },
                                })
                                return
                            }
                            if (!NAME_REGEX.test(firstName) || !NAME_REGEX.test(lastName)) {
                                toast.error('Firstname and Lastname must be Alpha or Alphanumeric', {
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                        maxWidth: 'unset',
                                    },
                                })
                                return
                            }

                            if (firstName && lastName) {
                                setShowGenderSection(true)
                            }
                        }}>
                        <div className='form-group flex gap-[20px] justify-end'>
                            {accountFor === 'Myself' ? <label htmlFor=''>I'm</label> : ''}

                            <div className='w-[182px] flex flex-col gap-[32px]'>
                                <input type='text' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                <input type='text' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <button></button>
                    </form>
                </div>
            )}

            {showGenderSection && (
                <div className='gender-wrap '>
                    <div className='form-heading'>
                        <p>Nice to meet you {firstName}!</p>
                        <p className='subtitle'>Are you Male or Female?</p>
                    </div>
                    <div className='form-group flex gap-[20px] justify-end items-center'>
                        {gender === 'Male' ? <Male /> : gender === 'Female' ? <Female /> : null}

                        <div className='radio-btn-wrap  flex flex-col gap-[17px] justify-end'>
                            <label className='radio male'>
                                <input type='radio' name='gender' value='Male' onChange={(e) => setGender(e.target.value)} />
                                <span>Male</span>
                            </label>
                            <label className='radio female'>
                                <input type='radio' name='gender' value='Female' onChange={(e) => setGender(e.target.value)} />
                                <span>Female</span>
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {showBDSection && (
                <div className='birthday-wrap '>
                    <div className='form-group'>
                        <p>Happy Birthday???</p>
                        <label htmlFor='emailphone'>Can you tell me your Birthdate?</label>
                        <DatePicker
                            selected={dob}
                            onChange={(date) => {
                                setDOB(date)
                                setShowOrganizationSection(true)
                            }}
                            showIcon
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown={true}
                            showYearDropdown
                            showMonthDropdown
                            toggleCalendarOnIconClick
                            dateFormat='dd MMM yyyy'
                            maxDate={new Date()}
                            icon={<Calender />}
                        />
                    </div>
                </div>
            )}

            {showOrganizationSection && (
                <div className='organization-wrap '>
                    <div className='form-group'>
                        <p>Tell us more...</p>
                        <label htmlFor='emailphone'>What is the name of your organization?</label>
                        <select
                            id='organization'
                            value={organizationName1}
                            onChange={(e) => setOrganizationName1(e.target.value)}
                            disabled={organizationName2}>
                            <option value=''>Select</option>
                            {organizationList.map((option) => {
                                return (
                                    <option key={option._id} value={option._id}>
                                        {option.name}
                                    </option>
                                )
                            })}
                        </select>
                        <p className='!py-[30px]'>Or</p>
                        <form action='' onSubmit={handleOrgInputSubmit}>
                            <input
                                type='text'
                                placeholder='Others'
                                value={organizationName2}
                                onChange={(e) => setOrganizationName2(e.target.value)}
                                disabled={organizationName1}
                            />
                        </form>
                        <p className='pt-[26px]'>(School Name, Self Learner, other Organization)</p>
                    </div>
                </div>
            )}

            {showSportsSection && (
                <div className='select-sports-wrap min-h-[718px] overflow-auto relative'>
                    <div className='form-heading sticky top-0 bg-black z-[1]'>
                        <p>Almost Done</p>
                        <p className='subtitle'>What sports do you play?</p>
                        <p className='subtitle'>Selected {sportsLiked.length}/3</p>
                        <input type='search' name='' id='' placeholder='Search Sports' className='mt-[26px]' onChange={handleSearch} />
                    </div>

                    <div className='sports-button-wrap w-[335px] mx-auto flex flex-wrap gap-4'>
                        {filteredSports.map((sport) => (
                            <button
                                key={sport.label}
                                className={sportsLiked.includes(sport.label) ? 'active' : ''}
                                onClick={() => handleSportSelect(sport.label)}>
                                {sport.icon}
                                {sport.label}
                            </button>
                        ))}
                    </div>

                    <button
                        className='next-button sticky right-0 bottom-0 float-end'
                        disabled={sportsLiked === 0}
                        onClick={() => setShowQualificationSection(true)}>
                        <Arrow />
                    </button>
                </div>
            )}

            {showQualificationSection && (
                <div className='qualification-wrap min-h-full'>
                    <div className='form-group'>
                        <p>Tell us about</p>
                        <label htmlFor='emailphone'>Your Educational Qualification</label>
                        <div className='radio-btn-wrap  flex flex-wrap gap-y-4 gap-x-2'>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='qualification' value='Post Graduate' onChange={(e) => setQualification(e.target.value)} />
                                <span>Post Graduate</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='qualification' value='Under Graduate' onChange={(e) => setQualification(e.target.value)} />
                                <span>Under Graduate</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='qualification' value='High School' onChange={(e) => setQualification(e.target.value)} />
                                <span>High School</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='qualification' value='Diploma' onChange={(e) => setQualification(e.target.value)} />
                                <span>Diploma</span>
                            </label>
                            <label className='radio  w-[50%:]'>
                                <input type='radio' name='qualification' value='In School' onChange={(e) => setQualification(e.target.value)} />
                                <span>In School</span>
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {showPrivacySection && (
                <div className='privacy-policy-wrap min-h-[718px] overflow-auto'>
                    <div className='form-heading sticky top-0 bg-black z-[1]'>
                        <p>And the final step</p>
                        <p className='subtitle'>Privacy Policy</p>
                    </div>
                    <div className='content-wrap !h-[600px] overflow-scroll' ref={privacyContainerRef}>
                        {policyContent.map((section, index) => (
                            <div key={index}>
                                <p className='title'>{parse(section.title)}</p>
                                <p>{parse(section.description)}</p>
                            </div>
                        ))}
                    </div>
                    <div className='btn-wrap pt-10 float-end sticky bottom-0'>
                        <button className='default-button' onClick={handleCreateProfile} disabled={!isPrivacySectionScroll}>
                            I agree
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
