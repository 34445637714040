import { ReactComponent as Acrobatics } from '../icons/acrobatics.svg'
import { ReactComponent as AlpineSkiing } from '../icons/alpine-skiing.svg'
import { ReactComponent as Archery } from '../icons/archery.svg'
import { ReactComponent as Gymnastics } from '../icons/gymnastics.svg'
import { ReactComponent as Swimming } from '../icons/swimming.svg'
import { ReactComponent as Athletics } from '../icons/athletics.svg'
import { ReactComponent as Badminton } from '../icons/badminton.svg'
import { ReactComponent as Cycling } from '../icons/cycling.svg'
import { ReactComponent as Boxing } from '../icons/boxing.svg'
import { ReactComponent as Canoe } from '../icons/canoe.svg'
import { ReactComponent as CrossCountry } from '../icons/cross-country.svg'
import { ReactComponent as Diving } from '../icons/diving.svg'
import { ReactComponent as Dressage } from '../icons/dressage.svg'
import { ReactComponent as Fencing } from '../icons/fencing.svg'
import { ReactComponent as Skating } from '../icons/skating.svg'
import { ReactComponent as FreestyleSkiing } from '../icons/freestyle-skiing.svg'
import { ReactComponent as Golf } from '../icons/golf.svg'
import { ReactComponent as Judo } from '../icons/judo.svg'
import { ReactComponent as Karate } from '../icons/karate.svg'
import { ReactComponent as Luge } from '../icons/luge.svg'
import { ReactComponent as MountainBike } from '../icons/mountain-bike.svg'
import { ReactComponent as Nordic } from '../icons/nordic.svg'
import { ReactComponent as RhythmicGymnastics } from '../icons/rhythmic-gymnastics.svg'
import { ReactComponent as RoadCycling } from '../icons/road-cycling.svg'
import { ReactComponent as RollerSkating } from '../icons/roller-skating.svg'
import { ReactComponent as Sailing } from '../icons/sailing.svg'
import { ReactComponent as Shooting } from '../icons/shooting.svg'
import { ReactComponent as SpeedSkating } from '../icons/speed-skating.svg'
import { ReactComponent as Skateboarding } from '../icons/skateboarding.svg'
import { ReactComponent as Skeleton } from '../icons/skeleton.svg'
import { ReactComponent as SkyJumping } from '../icons/sky-jumping.svg'
import { ReactComponent as Mountaineering } from '../icons/mountaineering.svg'
import { ReactComponent as Snowboarding } from '../icons/snowboarding.svg'
import { ReactComponent as SportCliming } from '../icons/sport-climing.svg'
import { ReactComponent as Surfing } from '../icons/surfing.svg'
import { ReactComponent as TableTennis } from '../icons/table-tennis.svg'
import { ReactComponent as Taekwondo } from '../icons/taekwondo.svg'
import { ReactComponent as Tennis } from '../icons/tennis.svg'
import { ReactComponent as TrackCycling } from '../icons/track-cycling.svg'
import { ReactComponent as Trampoline } from '../icons/trampoline.svg'
import { ReactComponent as Triathlon } from '../icons/triathlon.svg'
import { ReactComponent as Weightlifting } from '../icons/weightlifting.svg'
import { ReactComponent as Wrestling } from '../icons/wrestling.svg'
import { ReactComponent as Basketball } from '../icons/basketball.svg'
import { ReactComponent as Baseball } from '../icons/baseball.svg'
import { ReactComponent as BeachHandball } from '../icons/beach-handball.svg'
import { ReactComponent as Volleyball } from '../icons/volleyball.svg'
import { ReactComponent as Bobsleigh } from '../icons/bobsleigh.svg'
import { ReactComponent as Biathlon } from '../icons/biathlon.svg'
import { ReactComponent as Curling } from '../icons/curling.svg'
import { ReactComponent as Football } from '../icons/football.svg'
import { ReactComponent as Futsal } from '../icons/futsal.svg'
import { ReactComponent as Handball } from '../icons/handball.svg'
import { ReactComponent as Hockey } from '../icons/hockey.svg'
import { ReactComponent as IceHockey } from '../icons/ice-hockey.svg'
import { ReactComponent as Rowing } from '../icons/rowing.svg'
import { ReactComponent as Rugby } from '../icons/rugby.svg'
import { ReactComponent as Softball } from '../icons/softball.svg'
import { ReactComponent as WaterPolo } from '../icons/water-polo.svg'
import { ReactComponent as Chess } from '../icons/chess.svg'
import { ReactComponent as Squash } from '../icons/squash.svg'
import { ReactComponent as Snooker } from '../icons/snooker.svg'
import { ReactComponent as Bowling } from '../icons/bowling.svg'
import { ReactComponent as Yoga } from '../icons/yoga.svg'
import { ReactComponent as MiniGolf } from '../icons/mini-golf.svg'
import { ReactComponent as Tennikoit } from '../icons/tennikoit.svg'
import { ReactComponent as Kickboxing } from '../icons/kickboxing.svg'
import { ReactComponent as Aikido } from '../icons/aikido.svg'
import { ReactComponent as MartialArts } from '../icons/martial-arts.svg'
import { ReactComponent as Cricket } from '../icons/cricket.svg'
import { ReactComponent as Lacrosse } from '../icons/lacrosse.svg'
import { ReactComponent as Kabaddi } from '../icons/kabaddi.svg'
import { ReactComponent as Netball } from '../icons/netball.svg'
import { ReactComponent as SepakTakraw } from '../icons/sepak-takraw.svg'
import { ReactComponent as Carrom } from '../icons/carrom.svg'
import { ReactComponent as LawnBall } from '../icons/lawn-ball.svg'
import { ReactComponent as MountainBiking } from '../icons/mountain-biking.svg'
import { ReactComponent as RollerHockey } from '../icons/roller-hockey.svg'
import { ReactComponent as InlineHockey } from '../icons/inline-hockey.svg'
import { ReactComponent as FlagFootball } from '../icons/flag-football.svg'
import { ReactComponent as KhoKho } from '../icons/kho-kho.svg'
import { ReactComponent as BeachVolleyball } from '../icons/beach-volleyball.svg'
import { ReactComponent as Breaking } from '../icons/breaking.svg'


export const SportsList = [
    {
        label: 'Acrobatics',
        icon: <Acrobatics />,
    },
    {
        label: 'Alpine Skiing',
        icon: <AlpineSkiing />,
    },
    {
        label: 'Archery',
        icon: <Archery />,
    },
    {
        label: 'Gymnastics',
        icon: <Gymnastics />,
    },
    {
        label: 'Swimming',
        icon: <Swimming />,
    },
    {
        label: 'Athletics',
        icon: <Athletics />,
    },
    {
        label: 'Badminton',
        icon: <Badminton />,
    },
    {
        label: 'Cycling',
        icon: <Cycling />,
    },
    {
        label: 'Boxing',
        icon: <Boxing />,
    },
    {
        label: 'Canoe',
        icon: <Canoe />,
    },
    {
        label: 'Cross Country',
        icon: <CrossCountry />,
    },
    {
        label: 'Diving',
        icon: <Diving />,
    },
    {
        label: 'Dressage',
        icon: <Dressage />,
    },
    {
        label: 'Fencing',
        icon: <Fencing />,
    },
    {
        label: 'Skating',
        icon: <Skating />,
    },
    {
        label: 'Freestyle Skiing',
        icon: <FreestyleSkiing />,
    },
    {
        label: 'Golf',
        icon: <Golf />,
    },
    {
        label: 'Judo',
        icon: <Judo />,
    },
    {
        label: 'Karate',
        icon: <Karate />,
    },
    {
        label: 'Luge',
        icon: <Luge />,
    },
    {
        label: 'Mountain Bike',
        icon: <MountainBike />,
    },
    {
        label: 'Nordic',
        icon: <Nordic />,
    },
    {
        label: 'Rhythmic Gymnastics',
        icon: <RhythmicGymnastics />,
    },
    {
        label: 'Road Cycling',
        icon: <RoadCycling />,
    },
    {
        label: 'Roller Skating',
        icon: <RollerSkating />,
    },
    {
        label: 'Sailing',
        icon: <Sailing />,
    },
    {
        label: 'Shooting',
        icon: <Shooting />,
    },
    {
        label: 'Speed Skating',
        icon: <SpeedSkating />,
    },
    {
        label: 'Skateboarding',
        icon: <Skateboarding />,
    },
    {
        label: 'Skeleton',
        icon: <Skeleton />,
    },
    {
        label: 'Sky Jumping',
        icon: <SkyJumping />,
    },
    {
        label: 'Mountaineering',
        icon: <Mountaineering />,
    },
    // {
    //     label: 'Speed Skating',
    //     icon: <SpeedSkating />,
    // },
    {
        label: 'Snowboarding',
        icon: <Snowboarding />,
    },
    {
        label: 'Sport Climing',
        icon: <SportCliming />,
    },
    {
        label: 'Surfing',
        icon: <Surfing />,
    },
    // {
    //     label: 'Swimming',
    //     icon: <Swimming />,
    // },
    {
        label: 'TableTennis',
        icon: <TableTennis />,
    },
    {
        label: 'Taekwondo',
        icon: <Taekwondo />,
    },
    {
        label: 'Tennis',
        icon: <Tennis />,
    },
    {
        label: 'Track Cycling',
        icon: <TrackCycling />,
    },
    {
        label: 'Trampoline',
        icon: <Trampoline />,
    },
    {
        label: 'Triathlon',
        icon: <Triathlon />,
    },
    {
        label: 'Weightlifting',
        icon: <Weightlifting />,
    },
    {
        label: 'Wrestling',
        icon: <Wrestling />,
    },
    {
        label: 'Basketball',
        icon: <Basketball />,
    },
    {
        label: 'Baseball',
        icon: <Baseball />,
    },
    {
        label: 'BeachHandball',
        icon: <BeachHandball />,
    },
    {
        label: 'Volleyball',
        icon: <Volleyball />,
    },
    {
        label: 'Bobsleigh',
        icon: <Bobsleigh />,
    },
    {
        label: 'Biathlon',
        icon: <Biathlon />,
    },
    {
        label: 'Curling',
        icon: <Curling />,
    },
    {
        label: 'Football',
        icon: <Football />,
    },
    {
        label: 'Futsal',
        icon: <Futsal />,
    },
    {
        label: 'Handball',
        icon: <Handball />,
    },
    {
        label: 'Hockey',
        icon: <Hockey />,
    },
    {
        label: 'Ice Hockey',
        icon: <IceHockey />,
    },
    {
        label: 'Rowing',
        icon: <Rowing />,
    },
    {
        label: 'Rugby',
        icon: <Rugby />,
    },
    {
        label: 'Softball',
        icon: <Softball />,
    },
    {
        label: 'Water Polo',
        icon: <WaterPolo />,
    },
    {
        label: 'Chess',
        icon: <Chess />,
    },
    {
        label: 'Squash',
        icon: <Squash />,
    },
    {
        label: 'Snooker',
        icon: <Snooker />,
    },
    {
        label: 'Bowling',
        icon: <Bowling />,
    },
    {
        label: 'Yoga',
        icon: <Yoga />,
    },
    {
        label: 'Mini Golf',
        icon: <MiniGolf />,
    },
    {
        label: 'Tennikoit',
        icon: <Tennikoit />,
    },
    {
        label: 'Kickboxing',
        icon: <Kickboxing />,
    },
    {
        label: 'Aikido',
        icon: <Aikido />,
    },
    {
        label: 'Martial Arts',
        icon: <MartialArts />,
    },
    {
        label: 'Cricket',
        icon: <Cricket />,
    },
    {
        label: 'Lacrosse',
        icon: <Lacrosse />,
    },
    {
        label: 'Kabaddi',
        icon: <Kabaddi />,
    },
    {
        label: 'Netball',
        icon: <Netball />,
    },
    {
        label: 'Sepak Takraw',
        icon: <SepakTakraw />,
    },
    {
        label: 'Carrom',
        icon: <Carrom />,
    },
    {
        label: 'Lawn Ball',
        icon: <LawnBall />,
    },
    {
        label: 'Mountain Biking',
        icon: <MountainBiking />,
    },
    {
        label: 'Roller Hockey',
        icon: <RollerHockey />,
    },
    {
        label: 'Inline Hockey',
        icon: <InlineHockey />,
    },
    {
        label: 'Flag Football',
        icon: <FlagFootball />,
    },
    {
        label: 'Kho Kho',
        icon: <KhoKho />,
    },
    {
        label: 'Beach Volleyball',
        icon: <BeachVolleyball />,
    },
    {
        label: 'Breaking',
        icon: <Breaking />,
    },
]
