import toast from 'react-hot-toast'

import { API_URLS, ENVS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const getOrganizationList = async (onSuccess) => {
    try {
        const res = await AxiosInstance(ENVS.sportkeyz_base_url).get(API_URLS.SPORTKEYZ['get-organization-list'], {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            },
        })

        if (res.data?.status === 'success') {
            onSuccess?.(res.data)
        } else {
            toast.error('Something went wrong.', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
        }
    } catch (err) {
        let errorMessage = 'Something went wrong'
        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message
        }
        toast.error(errorMessage, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
    }
}
