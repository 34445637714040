import toast from 'react-hot-toast'

import { API_URLS, ENVS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const portalSignin = async ({ email }, onSuccess, onError) => {
    try {
        const res = await AxiosInstance(ENVS.node_tiger_base_url).post(
            API_URLS.NODE_TIGER['portal-signin'],
            {
                email,
                type: 'user',
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-token'),
                },
            }
        )
        if (res.data?.status === 'success') {
            onSuccess?.(res.data)
            const msg = res.data.message
            toast.success(msg, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
        } else {
            onError?.(res.data)
            const error = res.data.message
            toast(error, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
        }
    } catch (err) {
        let errorMessage = 'Something went wrong'
        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message
        }
        toast.error(errorMessage, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
    }
}
