import { API_URLS, ENVS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const privacyPolicy = async (onSuccess, onError) => {
    try {
        const res = await AxiosInstance(ENVS.sportkeyz_base_url).get(API_URLS.SPORTKEYZ['privacy-policy'], {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            },
        })

        if (res.data?.status === 'success') {
            onSuccess?.(res.data)
        } else {
            onError?.(res.data)
        }
    } catch (err) {
        onError?.(err)
    }
}
