import toast from 'react-hot-toast'

import { API_URLS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const verifyOtp = async ({ otp, phoneEmail }, onSuccess, onError) => {
    try {
        const res = await AxiosInstance().post(API_URLS.NODE_TIGER['verfiy-otp'], { otp, email: phoneEmail })
        console.log({ res })
        if (res.data?.status === 'success') {
            toast.success('OTP verified successfully', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            onSuccess?.(res.data)
        } else {
            toast.error('Something went wrong.', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            onError?.()
        }
    } catch (err) {
        let errorMessage = 'Something went wrong'
        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message
        }
        toast.error(errorMessage, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
        onError?.()
    }
}
