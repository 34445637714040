import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'

import Login from './components/login/login'
import Registration from './components/registration/registration'

function App() {
    // eslint-disable-next-line no-restricted-globals
    const params = new URL(location.href)
    const [tab, setTab] = useState('Login')

    return (
        <div
            className={`login-page-wrap ${
                params.searchParams.get('showCss') === 'false' ? '' : 'px-40 py-[30px]'
            } min-h-screen bg-center bg-no-repeat bg-cover`}
            style={
                params.searchParams.get('showCss') === 'false'
                    ? {}
                    : {
                          backgroundImage: `url("images/background.jpg")`,
                      }
            }>
            <Toaster position='top-center' />
            <div className='form-container w-[475px] h-[812px] pt-[25px] ml-auto relative scale-[0.8] origin-top'>
                <div className='login-register-tab flex gap-1 absolute w-max m-auto top-0 inset-x-0 bg-pure-black rounded-[100px] p-1'>
                    <button className={`tab-button ${tab === 'Login' ? 'active' : ''}`} data-tab='tab1' onClick={() => setTab('Login')}>
                        Login
                    </button>
                    <button className={`tab-button ${tab === 'Register' ? 'active' : ''}`} data-tab='tab2' onClick={() => setTab('Register')}>
                        Register
                    </button>
                </div>
                <div className='form-wrap bg-black w-full rounded-[20px] h-[812px] pt-[52px] pr-[38px] pl-[40px] pb-[42px]'>
                    {tab === 'Register' && <Registration setTab={setTab} />}
                    {tab === 'Login' && <Login />}
                </div>
            </div>
        </div>
    )
}

export default App
