import toast from 'react-hot-toast'

import { API_URLS, ENVS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const createProfile = async (
    {
        spid,
        firstName,
        lastName,
        email,
        phoneNumber,
        dob,
        gender,
        accountFor,
        organizationName,
        organizationID,
        qualification,
        sportsLiked,
        trustMeterScore,
        avatar,
    },
    onSuccess
) => {
    try {
        const res = await AxiosInstance(ENVS.sportkeyz_base_url).post(
            API_URLS.SPORTKEYZ['create-profile'],
            {
                spid,
                firstName,
                lastName,
                email,
                phoneNumber,
                dob,
                gender,
                accountFor,
                organizationName,
                organizationID,
                qualification,
                sportsLiked,
                trustMeterScore,
                avatar,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-token'),
                },
            }
        )
        console.log({ res })
        if (res.data?.status === 'success') {
            toast.success('Profile created successfully', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            onSuccess?.(res.data)
        } else {
            toast.error('Something went wrong.', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
        }
    } catch (err) {
        let errorMessage = 'Something went wrong'
        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message
        }
        toast.error(errorMessage, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
    }
}
