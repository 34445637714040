import toast from 'react-hot-toast'

import { API_URLS } from '../const'
import { AxiosInstance } from '../lib/axios'

export const sendOtp = async (email, onSuccess) => {
    try {
        const res = await AxiosInstance().post(API_URLS.NODE_TIGER['send-otp'], { email })
        console.log({ res })

        if (res.data?.status === 'success') {
            toast.success('OTP sent successfully', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            onSuccess?.()
        } else {
            toast.error('Something went wrong.', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
        }
    } catch (err) {
        let errorMessage = 'Something went wrong'
        if (err.response && err.response.data && err.response.data.message) {
            errorMessage = err.response.data.message
        }
        toast.error(errorMessage, {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        })
    }
}
