import React, { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import toast from 'react-hot-toast'

import { verifyOtp } from '../../services/verify-otp'
import { sendOtp } from '../../services/send-otp'
import { portalSignin } from '../../services/portal-signin'
import ErrorMessage from '../common/error-message'
import { EMAIL_REGEX } from '../../const'

export default function Login() {
    const [phoneEmail, setPhoneEmail] = useState('')
    const [otp, setOtp] = useState('')
    const ContainerRef = useRef()
    const [error, setError] = useState('')
    const [time, setTime] = useState(120)
    const intervalRef = useRef(null)
    const [isOtpVerified, setIsOtpVerified] = useState(false)
    const [showOtpSection, setShowOtpSection] = useState(false)

    const handleEmailSubmit = (e) => {
        e.preventDefault()

        if (phoneEmail.length < 3) {
            toast.error('Email length should be greater than 3 characters', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (phoneEmail.length > 50) {
            toast.error('Email length should be less than 50 characters', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    maxWidth: 'unset',
                },
            })
            return
        }

        if (!EMAIL_REGEX.test(phoneEmail)) {
            toast.error('Invalid Email', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            })
            return
        }

        portalSignin({ email: phoneEmail }, () => {
            setShowOtpSection(true)
        })
    }
    const handleOtpChange = async (e) => {
        setOtp(e)
        setError('')

        // below line is for testing the flow without use of api

        // if (e.length >= 6) {
        //     await verifyOtp(
        //         { otp: e, phoneEmail },
        //         (res) => {
        //             // setSpId(res.spid)
        //             // setShowAccountForSection(true)
        //             localStorage.setItem('access-token', res.accessToken)
        //         },
        //         () => {
        //             setError('Please enter a valid code')
        //         }
        //     )
        // }
    }

    const handleLogin = async () => {
        if (otp.length >= 6) {
            await verifyOtp(
                { otp, phoneEmail },
                (res) => {
                    localStorage.setItem('access-token', res.accessToken)
                    setIsOtpVerified(true)
                    toast('You have logged in successfully!', {
                        style: {
                            background: '#19191A',
                            borderRadius: '12px',
                            color: '#fff',
                            fontSize: '45px',
                            padding: '10px 20px',
                            maxWidth: 'unset',
                        },
                    })
                    setTimeout(() => {
                        window.location.href = 'https://www.sportskeyz.com/downloadtheapp'
                    }, 1000)
                },
                () => {
                    setError('Please enter a valid code')
                }
            )
        }
    }

    const resendOtp = async (event) => {
        event.preventDefault()
        sendOtp(phoneEmail, () => {})
        setTime(120)
    }

    useEffect(() => {
        if (showOtpSection) {
            ContainerRef.current.scroll(null, 250)

            intervalRef.current = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(intervalRef.current)
                        return prevTime
                    }
                    return prevTime - 1
                })
            }, 1000)

            return () => {
                clearInterval(intervalRef.current)
            }
        }
    }, [showOtpSection])

    const minutes = Math.floor(time / 60)
    const seconds = time % 60

    return (
        <div action='' className={`relative h-full overflow-auto scroll-smooth flex flex-col gap-[100px] pb-[300px]`} ref={ContainerRef}>
            <div className='phone-email-wrap'>
                <div className='form-heading'>
                    <p className='title'>Welcome back!</p>
                    <p className='sub-title'>Press Enter to go to the next step</p>
                </div>
                <form action='' onSubmit={handleEmailSubmit}>
                    <div className='form-group'>
                        <p>Let’s complete the basics</p>
                        <label htmlFor='emailphone'>Enter your Email or phone number</label>
                        <input type='text' value={phoneEmail} onChange={(e) => setPhoneEmail(e.target.value)} disabled={showOtpSection} />
                    </div>
                </form>
                {/* <div className='continue-using-wrap pt-10'>
                    <p className='text-lg font-medium leading-none text-white pb-5 block'>Or continue using</p>
                    <ul className='flex gap-4'>
                        <li>
                            <a href='#'>
                                <img src='images/google.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/facebook.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/linkedin.svg' alt='' />
                            </a>
                        </li>
                        <li>
                            <a href='#'>
                                <img src='images/instagram.svg' alt='' />
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>

            {showOtpSection && (
                <div className='verification-wrap'>
                    <div className='form-heading'>
                        <p className='title'>Verification</p>
                    </div>
                    <form action='' onSubmit={handleEmailSubmit}>
                        <div className='form-group'>
                            <p>Enter the code sent on</p>
                            <label htmlFor='emailphone'>{phoneEmail}</label>

                            <div className='text-center'>
                                <div className='otp-input-wrap pb-3'>
                                    <OtpInput
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputType='tel'
                                        inputStyle={{
                                            width: '50px',
                                            height: '80px',
                                            border: '0',
                                            margin: '0 2px',
                                            padding: '21px 14px',
                                            fontSize: '34px',
                                            color: error ? '#E30B5C' : '#ffffff',
                                            fontWeight: '400',
                                            caretColor: '#ffffff',
                                            outline: 'none',
                                            background: '#23282E',
                                        }}
                                        focusStyle={{
                                            border: '0',
                                            outline: 'none',
                                        }}
                                        renderInput={(props) => <input {...props} placeholder='X' />}
                                    />
                                </div>

                                {!isOtpVerified && (
                                    <div>
                                        <div className={`py-[12px] leading-[1] text-white`}>
                                            {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                                        </div>
                                        <div
                                            className={`resend-otp-box py-[12px] flex gap-[8px] justify-center items-center  ${
                                                time > 0 && !error && 'opacity-50'
                                            }`}>
                                            {error ? (
                                                <ErrorMessage message={error} />
                                            ) : (
                                                <p className='text-xs leading-[1] !pb-0'>Didn’t receive the verification OTP?</p>
                                            )}

                                            <button
                                                className='text-sm text-accent leading-[1] disabled:cursor-not-allowed'
                                                disabled={time > 0 && !error}
                                                type='button'
                                                onClick={resendOtp}>
                                                Resend Again
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='btn-wrap pt-[60px] float-end '>
                            <button className='default-button' type='button' onClick={handleLogin}>
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}
